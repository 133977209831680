import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import DrawSVGPlugin from 'gsap/DrawSVGPlugin'

gsap.registerPlugin(DrawSVGPlugin) 
gsap.registerPlugin(ScrollTrigger);

$(window).load(function () {
	if(!$('body').hasClass('page-template-tpl-landing-page') && !$('body').hasClass('page-template-tpl-home-2024')) {
    $('#page-wrap').fadeIn();
	}
});


jQuery(document).ready(function ($) {

	homepage2024: {
		
		if($('body').hasClass('page-template-tpl-home-2024') ) {			
		
			var hp24Tl = gsap.timeline({
				// ease: "power3.inOut",
				scrollTrigger: {
					trigger: '.homepage__preloader'						,
					// scrub: true,
					// pin: true,
				},									
			});
			hp24Tl.to($('body'), {
				// overflow: 'hidden'
			})
			// Draw SVG				
			// hp24Tl.from(".homepage__preloader--svg #path", {
			// 	 drawSVG: 0,
			// 	 duration: 2,
			// 	 ease: "power2.out"
			// });
			// Title Fade in
			hp24Tl.to(".title-line-1", {
				opacity: 1,
				duration: 0.9
			}, ">+0.6")
			hp24Tl.to(".title-line-2", {
				opacity: 1,
				duration: 0.9
			}, "<")
			hp24Tl.to(".preloader-button", {
				opacity: 1,
				duration: 0.9,			
			}, "<+=0.6")
			hp24Tl.to(".preloader-scroll", {
				opacity: 1,
				duration: 0.9,			
				onComplete: () => { document.getElementById('preloader-video').play() }
			}, "<")
			hp24Tl.to(".preloader-button", {
				background: "#4D86B2",
				duration: 0.05
			}, ">+=0.7")			
			hp24Tl.to(".preloader-button", {
				background: "#ED723E",
				duration: 0.05
			}, ">+=0.7")
			hp24Tl.to(".preloader-button", {
				background: "#DFB307",
				duration: 0.05
			}, ">+=0.7")
			hp24Tl.to(".preloader-button", {
				background: "#00704A",
				duration: 0.05,
				
			}, ">+=0.7")
			// hp24Tl.to(".homepage__preloader--video", {
			// 	y: 0,				
			// 	duration: 1.5,
				
			// 	ease: "power3.out",
			// 	onComplete: () => { document.getElementById('preloader-video').play() }
			// }, ">=+1")
			// hp24Tl.to(".homepage__preloader--video video", {
			// 	rotateX: 0,
			// 	duration: 1,
			// 	ease: "power3.out",
			// 	scale: 1,
				
			
			// }, ">-=0.6")
			// hp24Tl.to('.homepage__preloader-box-green', {
			// 	color: '#FFF'
			// 	// rotateX: '-90deg',				
			// 	// duration: 5,
			// 	// transformOrigin: 'bottom'
			// }, ">-=50%")
			// hp24Tl.to('.homepage__preloader-box-white', {
			// 	rotateX: '0',	
			// 	duration: 5,							
			// 	// transformOrigin: 'top'
			// }, "<")
		// 	hp24Tl.to(".title-line-1", {
		// 		color: 'white',
		// 		duration: 0.4
		// 	})
		// 	hp24Tl.to(".title-line-2", {
		// 		color: "white",
		// 		duration: 0.4
		// 	}, "<")
		// 	hp24Tl.to(".homepage__preloader--svg #path", {
		// 		stroke: "white",
		// 		duration: 0.4
		//    }, "<");
			hp24Tl.to($('body'), {
				// overflow: 'visible'
			})
			ScrollTrigger.refresh()

			// Scrolling Text
			var scrollTL = gsap.timeline({				
				scrollTrigger: {
					trigger: '.homepage__introduction--container',
					scrub: 0.2,
					pin: '.homepage__introduction',
					start: "top top",
					end: window.innerHeight * 6,
					onLeave: () => {
						gsap.to('.homepage__preloader', {
							opacity : 0,
						})
					},
					onEnterBack: () => {
						gsap.to('.homepage__preloader', {
							opacity : 1,
						})
					}
				},									
			});
			var valueWidth = $('.js-hp-values').width();			
			scrollTL.to('.js-hp-values', {
				x: -1 * valueWidth,
				
			})
			scrollTL.to('.homepage__introduction--container', {
				clipPath: 'inset(0 0 100%)'
			})
			ScrollTrigger.refresh()

			var playPause = document.getElementById("play-pause");
			var video = document.querySelector(".homepage__expanding-video--container video");
			video.addEventListener("click", function() {
			  if (video.paused == true) {
			    // Play the video
			    video.play();
			    // Update the button text to 'Pause'
			    playPause.classList.toggle('pause');
				setTimeout(()=> {
					gsap.to(playPause, {
					opacity: 0,
					})
				}, 3000);
				video.addEventListener('mouseenter', () => {
					// console.log('mouse-moving');
					gsap.to(playPause, {
						opacity: 1,
					})
				})
				video.addEventListener('mouseleave', () => {
					setTimeout(()=> {
						gsap.to(playPause, {
						opacity: 0,
						})
					}, 1500);
				})
			  } else {
			    // Pause the video
			    video.pause();
			
			    // Update the button text to 'Play'
			   playPause.classList.toggle('pause');
			  }
			});

			var videoTL = gsap.timeline({				
				scrollTrigger: {
					trigger: '.homepage__expanding-video',
					scrub: true,
					pin: true,
					// start: "top bottom",
					// end: window.innerHeight * 2
				},									
			});
			videoTL.to(".homepage__expanding-video-scale", {				
				duration: 3,
				ease: "power3.out",
				scale: 1,
				onComplete: () => {
					gsap.to(playPause, {
						bottom: 50,
						left: 50,
						top: "unset",
						transform: "none",
					})
				}
			}, "")
			videoTL.to(".homepage__expanding-video", {	
				backgroundColor: 'white',
				duration: 3,
			}, ">")
			videoTL.to(".homepage__expanding-video--container .homepage__expanding-video-scale", {				
				duration: 3,
				ease: "power3.out",
				scale: 0.60,	
				onComplete: () => {
					gsap.to(playPause, {
						bottom: "50%",
						left: "50%",				
						transform: "translate(-50%, 50%)",
					})
				}			
			}, ">")

			ScrollTrigger.refresh()
			// Sliding images

			let sections = gsap.utils.toArray(".panel");	
			let slides = document.querySelectorAll('.homepage__sliding-images--slide');
			// console.log(slides);
			let width = 0;
			[...slides].forEach((slide) => {				
				if(!$(slide).hasClass('last-slide')) {
					width += $(slide).width();
				}				
			})			
			let scrollTween = gsap.to('.homepage__sliding-images--container', {
				// xPercent: -200,
				x: -1 * (width + 220) , // minus start padding and gap between slides
				ease: "none", // <-- IMPORTANT!
				scrollTrigger: {
				  trigger: ".homepage__sliding-images--container",
				  pin: true,
				  scrub: 0.1,
				  start: "center center",
				  end: "+=3000px",
				  
				},
				onComplete: () => {		
					// ScrollTrigger.refresh()
					gsap.to('.homepage__sliding-images-clip', {
						clipPath: 'inset(0 0 100%)',				
						scrollTrigger: {
							trigger: '.homepage__sliding-images-clip',
							start: 'bottom bottom',
							// pin: '.homepage__sliding-images--container',
							// pinSpacing: false,
							// pinSpacer: false,
							scrub: true,
							end: "+=3000",
							// containerAnimation: scrollTween,
							// markers: true,
						},						
						onComplete: () => {
							gsap.to('.homepage__rolling-image--reveal-text', {
								y: -54
							})
						}
					});
				  }
			  });
			
			var slidingImages = document.querySelectorAll('.homepage__sliding-images--image');
			let i = 0;
			[...slidingImages].forEach((image) => {				
				i++;
				gsap.to(image, {
					width: '100%',
					height: '100%',	
					duration: 2,				
					scrollTrigger: {
						trigger: image,
						containerAnimation: scrollTween,
						start: "right right",
						scrub: true,
						end: "left left+=50"
						// toggleActions: "play none none reset",
						// id: "1",
					  },
					// onComplete: function() {
						// console.log(i);
						// console.log(slidingImages.length);
						// if(i == slidingImages.length) {
							
						// }
					// }
				})	
				let title = image.querySelector('.image-title');
				let text = image.querySelector('.image-text');
				gsap.to(title, {
					opacity: 1,
					duration: 1.2,
					scrollTrigger: {
						scrub: 1.2,
						trigger: image,
						containerAnimation: scrollTween,
						start: "right right",
						end: "center center"
					  }
				})
				gsap.to(text, {
					x: 0,
					duration: 1.2,
					scrollTrigger: {
						scrub: 1.2,
						trigger: image,
						containerAnimation: scrollTween,
						start: "right right",
						end: "center center"
					  }
				})				

			})	

			gsap.to('.last-slide', {
				opacity: 1,			
				x: 0,				
				scrollTrigger: {
					scrub: 1.2,
					trigger: [...slidingImages][2],
					scrub: true,
					containerAnimation: scrollTween,
					start: "left center",
					// end: "center center"
				  }	
			})
			ScrollTrigger.refresh()
			// Rolling image
			var scrollTriggerEnd = $('.homepage__rolling-image__stats').height() + 50;
			
			var rollingImage = gsap.timeline({
				scrollTrigger: {
					trigger: ".homepage__rolling-image__image",
					pin:true,
					// markers: true,
					scrub: 0.1, 
					start: "bottom bottom",
					end: "+=4000px"
				}
			})
			// News
			ScrollTrigger.refresh()
			var newsItems = document.querySelectorAll('[data-newsitem]');					
			var sectionHeight = $('.homepage__news').height() - 500;
					
			let itemNo = 0;

			[...newsItems].forEach(item => { 
				console.log((newsItems.length) - itemNo);
				var newsImage = document.querySelector('[data-newsimage="'+item.dataset.newsitem+'"]');      				
				var backgroundColour = document.querySelector('[data-newsbackground="'+((newsItems.length) - itemNo)+'"]');
				
				ScrollTrigger.create({
					trigger: item,
					start: `top center`,
					end: `+=${sectionHeight * 0.166667}`,    
					// markers: true, 
					onEnter: () => {
						gsap.to(item, {color: '#00704A', opacity: 1, duration: 0.3, fontWeight: 600})
						gsap.to(newsImage, { opacity: 1 , duration: 0.3 })	
						// gsap.to(backgroundColour, {clipPath: "inset(0 0 0%)"})					
					},
					onLeave: () => {
						gsap.to(item, {color: '#4A4A4A', opacity: 0.6, duration: 0.3, fontWeight: 300});
						if (item.dataset.newsitem != newsItems.length ) {
							gsap.to(newsImage, { opacity: 0 , duration: 0.3 })							
							gsap.to(backgroundColour, {clipPath: "inset(0 0 100%)", duration: 1.5})
						}						
					},
					onEnterBack: () => {
						gsap.to(item, {color: '#00704A', opacity: 1, duration: 0.3, fontWeight: 600}),
						gsap.to(newsImage, { opacity: 1 , duration: 0.3 })
						gsap.to(backgroundColour, {clipPath: "inset(0 0 0%)", duration: 1.5})
					},
					onLeaveBack: () => {
						gsap.to(item, {color: '#4A4A4A',  opacity: 0.6, duration: 0.3, fontWeight: 300});
						if (item.dataset.newsitem != 1) {							
							gsap.to(newsImage, { opacity: 0 , duration: 0.3 })
						}												
					}
				});
				itemNo++; 				
			})
		}

	}

	slider: {
		$('.article-slider__details').slick({
			dots: true
			, arrows: false
			, fade: true
			, autoplay: true
			, autoplaySpeed: 4500
			, asNavFor: '.article-slider__images'
		, });
		$('.article-slider__images').slick({
			dots: false
			, arrows: false
			, autoplay: true
			, autoplaySpeed: 4500
			, asNavFor: '.article-slider__details'
		, });
		$('.js-vodcasts-slider').slick({
			dots: true,
			arrows: true,
			// autoplay: true, 
			adaptiveHeight: true,
			infinite: true,
			centerMode: true,
			slidesToShow: 1,
			autoplaySpeed: 4500
			// , asNavFor: '.article-slider__details'
		});
		// $('.footer__logos').slick({
		// 	dots: true
		// 	, arrows: false
		// 	, fade: false
		// 	, autoplay: true
		// 	, autoplaySpeed: 4500
		// 	, mobileFirst: true
		// 	, slidesToShow: 1
		// 	, slidesToScroll: 1
		// 	, responsive: [
		// 	   {
		// 		  breakpoint: 899,
		// 		  settings: "unslick"
		// 	   },{
		// 		breakpoint: 600,
		// 		settings: {
		// 			slidesToShow: 3,
		// 			slidesToScroll: 1,
		// 		}
			
		//  },
		   
		// 	]
		// , });
		
	}
	anchorlinks: {
	
	}
	fixedheader: {
		$(function () {
			$(window).scroll(function () {
				if ($(window).scrollTop() >= 150) {
					$('.header').addClass('fixed-header');
				}
				if ($(window).scrollTop() >= 160) {
					$('.header').addClass("transform-none");
				}
				else {
					$('.header').removeClass('fixed-header');
					$('.header').removeClass("transform-none");
				}
			});
		});
	}
	mobile: {
		///MOBILE MENU - FOR USE WITH TWK_NAV_WALKER
		//                $('.menu .menu-item-has-children > a').click(function (event) {
		//                    event.preventDefault();
		//                    $(this).parent().children('li .sub-menu-wrap').addClass('transform-none');
		//                });
		//                $('.back-menu').click(function () {
		//                    $(this).parent().parent().removeClass('transform-none');
		//                });
	}
	magnific: {
		///IFRAME
		$('.magnific-video').magnificPopup({
			type: 'iframe'
			, mainClass: 'mfp-fade'
			, removalDelay: 160
			, preloader: false
			, fixedContentPos: false
		});
		// INLINE
		$('.magnific-inline').magnificPopup({
			type: 'inline',
			fixedContentPos: false,
			fixedBgPos: true,
			overflowY: 'auto',
			closeBtnInside: true,
			preloader: false,
			midClick: true,
			removalDelay: 300,
			mainClass: 'my-mfp-zoom-in'
		});
        // GALLERY IMAGE
        $('.magnific-gallery-image').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {
                verticalFit: true,
                titleSrc: function(item) {
                    return item.el.attr('title');
                }
            },
            gallery:{
                enabled:true
            }
        });
	}

	responsiveIfrmae : {
		if (document.querySelectorAll('.article iframe').length) {
			const iframes = document.querySelectorAll('.article iframe');
			
				var wrap = function (toWrap, wrapper) {
				  wrapper = wrapper || document.createElement('div');
				  wrapper.classList.add('embed-responsive');
				  wrapper.classList.add('embed-responsive-16by9');
				  toWrap.parentNode.appendChild(wrapper);
				  return wrapper.appendChild(toWrap);
			  };
			  [...iframes].forEach((iframe) => {
			  wrap(iframe);
			
			  });
			}
	}
	
	externallinks: {
		// $('.footer a').each(function () {
		// 	var a = new RegExp('/' + window.location.host + '/');
		// 	if (!a.test(this.href)) {
		// 		$(this).click(function (event) {
		// 			event.preventDefault();
		// 			window.open(this.href, '_blank');
		// 		});
		// 	}
		// });
		pdfs: {
			///OPEN PDFS IN NEW WINDOW
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}
	links: {
		$(document).on('click', '.scroll-down', function (event) {
			event.preventDefault();
			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		});
	}
	search: {
		// $('.search-icon').on('click', function (e) {
		// 	$('.searchform').toggleClass('search-open');
		// 	$(this).toggleClass('submit-zindex');
		// 	$('.select-lang').removeClass('transform-height');
		// 	$('#menu-main-menu-top, .lang_dropdown').fadeOut();
		// 	$("#s").focus();
		// 	setTimeout(function () {
		// 		$('.close-search').fadeIn();
		// 	}, 300);
		// });
		// $('.close-search').on('click', function (e) {
		// 	$(this).fadeOut();
		// 	$('.searchform').removeClass('search-open');
		// 	$('.search-icon').removeClass('submit-zindex');
		// 	$('#menu-main-menu-top, .lang_dropdown').fadeIn();
		// });
	}
	loadingAnimations: {
		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop()
			, };
			viewport.bottom = viewport.top + win.height() - 100;
			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();
			return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
		};
		$(window).scroll(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
	}
	utils: {
		function hasClass(el, className) {
            return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
        }
        
        function addClass(el, className) {
            if (el.classList) {
                el.classList.add(className);
            } else if (!hasClass(el, className)) {
                el.className += ' ' + className;
            }
        }
        
	}
	onLoad: {
		$(document).ready(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
    }
    menu: {
        const pageWrap = document.querySelector('#content-wrap');
        const burger = document.querySelector('.hamburger');
        const menu = document.querySelector('.header__menu__main');
		const closeMenu = document.querySelector('.header__menu__main__close');
		const closeButtons = document.querySelectorAll('.back-button');
        //const menuAnimate = document.querySelector('.header__menu__main .off-screen');
        const menuItems = document.querySelectorAll('#menu-main-menu > li.menu-item-has-children');
        const subMenus = document.querySelectorAll('.header__menu__sub');

        //animate burger
        function toggleOpen(element) {
            element.classList.toggle('open');
            //element.classList.toggle('off-screen--static');
        }

        function dullBackground(element) {
            element.classList.toggle('menu-open');
            //element.classList.toggle('off-screen--static');
        }

        //animate burger and menu on click on hamburger
        burger.onclick = function(){
            toggleOpen(burger);
            //toggleOpen(menuAnimate);
            toggleOpen(menu);
			dullBackground(pageWrap);
		};

		pageWrap.onclick = function(){
			// if(hasClass(pageWrap, 'menu-open')) {
			// 	toggleOpen(burger);
			// 	//toggleOpen(menuAnimate);
			// 	toggleOpen(menu);
			// 	dullBackground(pageWrap);
			// }
		};
		

        closeMenu.onclick = function(){
            toggleOpen(burger);
            //toggleOpen(menuAnimate);
            toggleOpen(menu);
			dullBackground(pageWrap);
			menuItems.forEach(function(menuItem) {
				menuItem.classList.remove('active');
			});
            subMenus.forEach(function(subMenu) {
                subMenu.classList.remove('open');
            });
		};
		
		closeButtons.forEach(function(button) {
			button.onclick = function(){
				subMenus.forEach(function(subMenu) {
					subMenu.classList.remove('open');
				});
				menuItems.forEach(function(menuItem) {
					menuItem.classList.remove('active');
				});
			}
		});

        menuItems.forEach(function(item) {
            item.children[0].onclick = function(e){
                e.preventDefault();
                menuItems.forEach(function(menuItem) {
                    menuItem.classList.remove('active');

                });
                item.classList.add('active');
                subMenus.forEach(function(subMenu) {
                    subMenu.classList.remove('open');
                    //subMenu.classList.add('off-screen--static');
                });

                let submenu = item.childNodes;
                toggleOpen(submenu[2]); 
            };
		});
		
		$('#content-wrap').on('click', function() {
			burger.classList.remove('open');
            menu.classList.remove('open');
			pageWrap.classList.remove('menu-open'); 
			subMenus.forEach(function(subMenu) {
                subMenu.classList.remove('open');
            });
		});
    }
    img: {
        
        let imgContainers;
        let len;
        
        if (!Modernizr.objectfit) {
            imgContainers = document.querySelectorAll('.image-wrap');
            len = imgContainers.length;
        
            for (var i=0; i<len; i++) {
                const container = imgContainers[i],
                    imgUrl = container.querySelector('img').getAttribute('src');

                if (imgUrl) {
                    container.style.backgroundImage = 'url(' + imgUrl + ')';
                    addClass(container, 'image-wrap--ie');  
                }
            }
        }
    }

	ajax: {

    // function get_url_var(variable, url) {
    //     let arrVals = url.split("/");
    //     let found = 0;
    //     let iterator = 0;
    //     arrVals.forEach(function (item) {
    //         // do something with `item`
    //         if (item == variable) found = iterator;
    //         iterator++;
    //     });
    //     return arrVals[found + 1];
    // };

    // function loadArticle(pageNumber, category, postType) {
    //     pageNumber = typeof pageNumber !== 'undefined' ? pageNumber : 1;
    //     category = typeof category !== 'undefined' ? category : '';
    //     $.ajax({
    //         url: "/wp-admin/admin-ajax.php"
    //         , type: 'POST'
    //         , beforeSend: function (request) {
    //             request.setRequestHeader("Authorization", "Negotiate");
    //         }
    //         , data: "action=loadMore&page_no=" + pageNumber + "&category=" + category + "&twk_type=" + postType
    //         , success: function (html) {
    //             if (html == '<h2>Nothing found</h2>') {
    //                 $('.ajax-container').append(html);
    //                 $('.ajax-loader__button').fadeOut();
    //             }
    //             else {
    //                 $('.ajax-container').append(html);
    //                 // $('.off-screen').each(function (index) {
    //                 //     if ($(this).isOnScreen()) {
    //                 //         $(this).removeClass('off-screen--hide');
    //                 //     }
    //                 // });
    //             }
    //         }
    //         , error: function (error) {
    //             console.info(error);
    //         }
    //     });
    //     return false;
    // };

    // $('.ajax-loader__button').on('click', function (e) {
    //     e.preventDefault();
    //     var href = $(e.target).attr('href');
    //     //		console.info(href);
    //     var pageNumber = get_url_var('page', href);
    //     var nextPageNumber = parseInt(pageNumber) + 1;
    //     var category = get_url_var('category', href);
    //     $(e.target).attr('href', href.replace('page/' + pageNumber, 'page/' + nextPageNumber));
	// 	   console.info(href);
    //     console.info(pageNumber);
    //     console.info(nextPageNumber);
    //     console.info(category);
    //     //		$(e.target).data('post-type');
    //     //		var postType = $(e.target).data('post-type');
    //     var postType = 'post';
    //     //		if(){
    //     //			postType = 'publications';
    //     //		} 
    //     console.info(postType);
    //     loadArticle(pageNumber, category, postType);
    // });
    }
    parallax: {
		// $.fn.moveIt = function(){
		// 	var $window = $(window);
		// 	var instances = [];
			
		// 	$(this).each(function(){
		// 	  instances.push(new moveItItem($(this)));
		// 	});
			
		// 	window.addEventListener('scroll', function(){
		// 	  var scrollTop = $window.scrollTop() / 2;
		// 	  instances.forEach(function(inst){
		// 		inst.update(scrollTop);
		// 	  });
		// 	}, {passive: true});
		//   }
		  
		//   var moveItItem = function(el){
		// 	this.el = $(el);
		// 	this.speed = 7;
		//   };
		  
		//   moveItItem.prototype.update = function(scrollTop){
		// 	this.el.css('transform', 'translateY(' + -(scrollTop / this.speed) + 'px)');
		//   };
		  
		//   // Initialization 
		//   $(function(){
		// 	$('[data-scroll-speed]').moveIt();
		//   });
	}
	modal: {
		let modalAnchors = document.querySelectorAll('.modal-anchor');
		let modalClose = document.querySelectorAll('.modal__close');
		let modals =  document.querySelectorAll('.modal');

		//open the modals
		modalAnchors.forEach(function(anchor) {
            anchor.onclick = function(e){
				let modalID = anchor.getAttribute("modal-id");
				let modalChild = document.querySelector('#'+modalID);
				modalChild.classList.toggle('modal--open');
				$(modalChild).fadeIn();
            };
		});
		
		//close the modals
		
		modalClose.forEach(function(close) {
            close.onclick = function(e){
				modals.forEach(function(modal) {
					modal.classList.remove('modal--open');
					$(modal).fadeOut();
				});
            };
		});
		  
	}
	ajaxpage: {
		$('.ajax-anchor').on('click', function() {
			let url = $(this).attr('ajax-url');
			window.history.pushState( null , null, url );
			//window.history.popState( null , null, url );
			var pageID = $(this).attr('ajax-target');
			$('.ajax-anchor').removeClass('current_page_item');
			$(this).addClass('current_page_item');
            indexAjax(pageID);
		});
	}
	accordion: { 
		function toggleAccordion() {
	
			$('.js-accordion-target').not($(this).next('.js-accordion-target')).slideUp();
			$('.js-accordion-plus').not($(this).find('.js-accordion-plus')).removeClass('active');
			$(this).next('.js-accordion-target').slideToggle();
			$(this).find('.js-accordion-plus').toggleClass('active'); 
			// setTimeout(function () {
			// 	var resizeEvent = window.document.createEvent('UIEvents'); 
			// 	resizeEvent.initUIEvent('resize', true, false, window, 0); 
			// 	window.dispatchEvent(resizeEvent);
			// }, 500);
		}
		
		const accordions = document.querySelectorAll(".js-accordion-trigger");
		accordions.forEach(function(accordion) {
			accordion.addEventListener("click", toggleAccordion, false);
		});
	}
	scroll: {
	
		// $('.ajax-loader__button').on('click', function () {
		// 	scroll.update();
		// });
	}
	index: {
		// $('.sf-field-taxonomy-school li').on('click', function() {
		// 	var resizeEvent = window.document.createEvent('UIEvents'); 
		// 	resizeEvent.initUIEvent('resize', true, false, window, 0); 
		// 	window.dispatchEvent(resizeEvent);
		// });
	}
	backbutton: {
	
		
	}
	counters: {
		
	}
	payment: {
	
			// jQuery('.gform_wrapper input[type=submit]', this).click(function() {
			// 	jQuery('.gform_wrapper input[type=submit]').css('display','none');
			// });
	
	}
	tables: {
		$('table').wrap('<div class="tables-responsive"></div>')
	}

	bannernotice: {
		// setTimeout(function() {
		// 	$('.banner-notice').fadeIn(); 
		// }, 500);
		// console.info(Cookies.get('accept_local_trust_cookies'));
        // if (!Cookies.get('accept_local_trust_cookies')) {
        //     $('.cookie-bar').fadeIn();
        // }
        // $('.js-accept-cookies').on('click', function () {
        //     Cookies.set('accept_local_trust_cookies', 'true', {
        //         expires: 365
        //     });
        //     $('.cookie-bar').fadeOut();
        // })
        // console.info(Cookies.get('accept_local_trust_notification'));
        // if (!Cookies.get('accept_local_trust_notification')) {
        //     $('.notification-banner').fadeIn();
        // }
        // $('.notification-banner__close').on('click', function () {
        //     Cookies.set('accept_local_trust_notification', 'true', {
        //         expires: 7
        //     });
        //     $('.notification-banner').fadeOut();
        // })

 
  
        
    
          
	}

	landingPageAnimations: {
		if($('body').hasClass('page-template-tpl-landing-page')) {
		// $(window).load(function () {

			const sections = document.querySelectorAll('section');

			// console.log(sections);
			[...sections].forEach(section => {
				console.log(section);
				let bannerImage = $(section).find('.js-banner-image img');
				let mainTitle = $(section).find('.js-title span');
				let textBlock = $(section).find('.js-text-block');
				let gridItem = $(section).find('.js-grid-item');

				var tl = gsap.timeline({
					ease: "power3.inOut",
					scrollTrigger: {
						once: true,
						trigger: section,
						// markers: true,
						start: "top center+=250",						
					},									
				});
				if(bannerImage.length){
				tl.to(bannerImage, {
					autoAlpha: 1,
					duration: 0.3
				}, "")
				}
				if(mainTitle.length) {
				tl.fromTo(mainTitle, {
					y: "113%"
				}, {
					duration: 0.9,
					y: "0%",					
					ease: "power3.out",
					stagger: 0.4,
					// delay: 4
				}, ">")
				}	
				if(textBlock.length) {
				tl.to(textBlock, {
					opacity: 1,
					duration: 0.5,
				})
				}
				if(gridItem.length) {
				tl.fromTo(gridItem, {
					opacity : 0,
					scale: 0.2,
					perspective: 3000,
					z: -3000,
					rotateY: 180,
				}, {
					scale: 1,
					rotateY: 0,
					z: 0,
					duration: 0.7,
					opacity: 1,
					// ease: "power3.out",
					stagger: {
						// grid: [7,15],
						from: "start",
						// axis: "x",
						each: 0.15,
					  }				
				}, ">")
				}
			})
			

		// })
		}
	}
	
}); 

